import { createApp } from 'vue'
import App from './App.vue'
import router from '@/plugins/router'
import Vant from 'vant'

import 'vant/lib/index.css'

const app = createApp(App)
//整个应用支持路由。
app.use(router)
app.use(Vant);

app.mount('#app')
