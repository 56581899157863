import Storage from './storage'

let storage = new Storage()

const KEY = 'user.h5'

/**
 * @returns {boolean}
 */
export function checkLogin () {
    let userInfo = storage.get(KEY)

    if(!userInfo.expired_time){
        return false
    }

    let time = parseInt(Date.parse(new Date()) / 1000)

    if(userInfo.expired_time < time){
        return false
    }

    return true
}

/**
 * @returns {*}
 */
export function getUser () {
    return storage.get(KEY)
}

/**
 * @returns {*}
 */
export function getAccessToken () {
    return getUser().access_token
}

/**
 * @param userInfo
 */
export function loginUser (userInfo) {

    let data = userInfo.user
    data.access_token = userInfo.access_token
    data.expired_time = parseInt(Date.parse(new Date()) / 1000) + userInfo.expire_in

    storage.set(KEY, data)
}

/**
 * 退出登陆
 */
export function logoutUser () {
    storage.delete(KEY)
}
