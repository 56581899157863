<template>
    <router-view/>
</template>

<style>
body{
    margin: 0 !important;
    background-color:#eee;
}
.card-box {
  margin: 0 10px;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 1px 2px 1px #efefef;
}
.mt-10{
  margin-top: 10px;
}
.txt-r{
  text-align:right;
}
.txt-c{
  text-align:center;
}
</style>
